import CryptoJS from "crypto-js";


export default {
   
    // 加密
    encrypt(word, keyStr, ivStr) {  
        keyStr = keyStr ? keyStr : 'WIl8Z3xKPLe7KXOrILzAhwciRDA4DxbstOK+rRvfaQU=';
        ivStr = ivStr ? ivStr : 'i5mZxgcZhuUMIl/VgmsZPw==';
        keyStr =keyStr.PadRight(keyStr,32)
        ivStr =  ivStr.PadRight(16,' ');
        let key = CryptoJS.enc.Utf8.parse(keyStr);
        let iv = CryptoJS.enc.Utf8.parse(ivStr);
        let srcs = CryptoJS.enc.Utf8.parse(word);
        let encrypted = CryptoJS.AES.encrypt(srcs, key, {
            iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
        return encrypted.toString();
    },
    // 解密
    decrypt(word, keyStr, ivStr) {
      // console.time('t1')
       
        keyStr = this.padright(keyStr,32,' ');
        ivStr =  this.padright(ivStr,16,' ');
        let key = CryptoJS.enc.Utf8.parse(keyStr);
        let iv = CryptoJS.enc.Utf8.parse(ivStr);
        var decrypt = CryptoJS.AES.decrypt(word, key, {
            iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
        // console.timeEnd('t1');
        return decrypt.toString(CryptoJS.enc.Utf8);
    },
    //不足补全，然后截取
    padright (strSource,len, charStr) {
      if(strSource<length){
        var s = strSource + '';
        return s + new Array(len - s.length + 1).join(charStr, '');
      }else{
        return strSource.substring(0,len)
      }
    },

};

