import Vue from 'vue'
import Vuex from 'vuex'
import API from '@/api/dsgcApi'

Vue.use(Vuex)

//准备actions对象——响应组件中用户的动作、处理业务逻辑
const actions = {}
//准备mutations对象——修改state中的数据
const mutations = {
  GETTICKET(state, value) {
    state.ticket = value
  },
  SET_ACTIVEE(state, value) {

    state.active = value;
  },
  coverTableData(state,data){
    state.tableData = data
  },
  changeActiveName(state,value){
    state.activeName = value

  },
}
//准备state对象——保存具体的数据
const state = {
  ticket: '',
  tableData:{},
  activeName:'1',
  token:'9905CFCB635F8877316DCE24428A97BA',

}

//创建并暴露store
export default new Vuex.Store({
  actions,
  mutations,
  state,
})
